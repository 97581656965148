import React, { useMemo } from 'react'
import Icon from '../icon'

export default function StarRating({
  statNum,
  label,
  statType = null,
  onClick,
}) {
  // label is included to make unique keys
  const stArr = []
  const statNumRounded = useMemo(() => Math.round(statNum * 2) / 2, [statNum])
  for (let i = 0; i < 5; i += 1) {
    if (statNumRounded >= i + 1) {
      stArr.push(
        <Icon name={'star'} className="starIcon" key={`${label} ${i}`} />,
      )
    } else if (statNumRounded >= i + 0.5) {
      // check if full star
      stArr.push(
        <Icon
          name={'star-half-stroke'}
          className="starIcon"
          key={`${label} ${i}`}
        />,
      )
    } else {
      // check if half star
      stArr.push(
        <Icon
          name={'star-regular'}
          className="starIcon"
          key={`${label} ${i}`}
        />,
      )
    } // empty star
  }
  return (
    <>
      <div className="display-3 numberValue">{statNum}</div>
      <span className="starRating">{stArr}</span>
      {statType && (
        <p
          className="mt-2"
          style={{
            textDecoration: onClick && 'underline',
            cursor: onClick && 'pointer',
          }}
          onClick={onClick}
        >
          {statType}
        </p>
      )}
    </>
  )
}
