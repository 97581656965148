import React, { useRef, useEffect, useState, useCallback } from 'react'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import { useTranslation } from 'react-i18next'
import { Container, Row, Col, Card } from 'react-bootstrap'

import './civic-participation.scss'
import useApi from '../../hooks/useApi'
import { useCurrentUser } from '../../hooks/useCurrentUser'
import Loading from '../../components/loading'
import Title from '../../components/title'
import Icon from '../../components/icon'
import EzTooltip from '../../components/tooltip'
import StarRating from '../../components/CivicPartitipation/StarRating'
import StatNumber from '../../components/CivicPartitipation/StatNumber'
import StatBox from '../../components/CivicPartitipation/StatBox'
import Badge from '../../components/badge'
import MyEventsModal from './MyEventsModal/MyEventsModal'

export default function CivicParticipation() {
  const { t } = useTranslation()
  const { currentUser, hasBetaFeature } = useCurrentUser()
  const { getCivicParticipation } = useApi()
  const [dates, setDates] = useState({
    start: moment().subtract(12, 'months'),
    end: moment(),
  })
  const [data, setData] = useState(null)
  const rateLimit = useRef(null)
  const firstRender = useRef(true)
  const [personalImpactArray, setPersonalImpactArray] = useState([])
  const [collateralImpactArray, setCollateralImpactArray] = useState([])
  const [ratingArray, setRatingArray] = useState([])
  const [showMyEventRatingDetails, setShowMyEventRatingDetails] =
    useState(false)

  const handleCloseMyEventRatingDetails = () =>
    setShowMyEventRatingDetails(false)
  const handleOpenMyEventRatingDetails = () => setShowMyEventRatingDetails(true)

  const fetchData = useCallback(async () => {
    // Get latest user data
    if (data) return
    const civic = await getCivicParticipation({
      userId: currentUser.id,
      start_date: dates.start.format('M-D-YYYY'),
      end_date: dates.end.format('M-D-YYYY'),
    })
    setData({
      stats: {
        ...civic.data,
      },
    })
  })

  const updateData = () => {
    if (rateLimit.current) {
      clearTimeout(rateLimit.current)
      rateLimit.current = setTimeout(() => {
        rateLimit.current = null
        updateData()
      }, 500)
      return
    }

    rateLimit.current = setTimeout(() => {
      rateLimit.current = null
    }, 500)
    const civic = new Promise(resolve => {
      resolve(
        getCivicParticipation({
          userId: currentUser.id,
          start_date: dates.start.format('M-D-YYYY'),
          end_date: dates.end.format('M-D-YYYY'),
        }),
      )
    }).then(response => {
      setData(data => ({
        ...data,
        stats: {
          ...response.data,
        },
      }))
    })
  }

  useEffect(() => {
    fetchData()
  }, [])

  const infoMark = text => {
    return (
      <EzTooltip
        outerContent={<Icon name="info" className="btn btn-lg btn-link" />}
        innerContent={text}
        type="secondary"
        placement="top"
        id={text}
      />
    )
  }

  const handleDateChange = (key, value) => {
    setDates(dates => ({
      ...dates,
      [key]: value,
    }))
  }

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false
      return
    }
    updateData()
  }, [dates])

  useEffect(() => {
    if (data) {
      const iArray = [
        {
          label: t('users.civic_participation.awareness'),
          statNum: data.stats.reported_count,
          statType: t('users.civic_participation.comm_needs_label'),
        },
        {
          label: t('users.civic_participation.events_header'),
          statNum: data.stats.event_count,
          statType: t('users.civic_participation.events_label'),
        },
        {
          label: t('users.civic_participation.work_header'),
          statNum: data.stats.hours_volunteered,
          statType: t('users.civic_participation.hours'),
        },
        {
          label: t('users.civic_participation.attended'),
          statNum: data.stats.participated_events,
          statType: t('users.civic_participation.attended_label'),
        },
      ]
      if (hasBetaFeature('civ_wallet')) {
        iArray.push({
          label: (
            <div className="badge-container justify-content-center">
              {t('users.civic_participation.donated_header')}
              <Badge />
            </div>
          ),
          statNum: data.stats.donated_amount,
          statType: 'CIV',
        })
      }
      setPersonalImpactArray(iArray)
      const cArray = [
        {
          label: t('users.civic_participation.vol_signup'),
          statNum: data.stats.volunteers_count,
          statType: t('users.civic_participation.volunteers'),
        },
        {
          label: t('users.civic_participation.vol_hours'),
          statNum: data.stats.volunteer_hours,
          statType: t('users.civic_participation.total_vol_hours'),
        },
        {
          label: t('users.civic_participation.participation_header'),
          statNum: data.stats.participants_count,
          statType: t('users.civic_participation.participants'),
        },
      ]
      setCollateralImpactArray(cArray)
      const rArray = [
        {
          onClick: handleOpenMyEventRatingDetails,
          label: t('users.civic_participation.my_events'),
          statNum: data.stats.average_event_rating,
          statType: t('users.civic_participation.review_count', {
            count: data.stats.event_rating_count,
          }),
        },
        {
          label: t('users.civic_participation.my_vol'),
          statNum: data.stats.average_volunteer_rating,
          statType: t('users.civic_participation.review_count', {
            count: data.stats.volunteer_rating_count,
          }),
        },
      ]
      setRatingArray(rArray)
    }
  }, [data])

  if (!data) return <Loading show />
  return (
    <Container>
      <Col>
        <h1>{t('navigation_banner.civic_participation_link')}</h1>
        <h6 className="block-description">
          {t('users.civic_participation.overview')}
        </h6>
        <br />

        <h3 className="CPsectionTitle mt-4">
          {t('users.civic_participation.timeline')}
          {infoMark(t('users.civic_participation.timeline_subheader'))}{' '}
        </h3>
        <div className="smallCenter ms-5 mt-4 mb-5">
          <div>
            {t('users.civic_participation.from')}{' '}
            <span className="datePickerWidth">
              <DatePicker
                className="form-control civic-datepicker"
                shouldCloseOnSelect
                selected={new Date(dates.start)}
                onChange={date => handleDateChange('start', moment(date))}
              />
            </span>
          </div>
          <div>
            {t('users.civic_participation.date_sep')}
            <span className="datePickerWidth">
              <DatePicker
                className="form-control civic-datepicker"
                shouldCloseOnSelect
                selected={new Date(dates.end)}
                onChange={date => handleDateChange('end', moment(date))}
              />
            </span>
          </div>
        </div>
        <br />
        <h3 className="CPsectionTitle">
          {t('users.civic_participation.impact_header')}
          {infoMark(t('users.civic_participation.impact_subheader'))}{' '}
        </h3>

        <Row className="mt-3 mb-5">
          {personalImpactArray.map(impact => (
            <StatBox
              label={impact.label}
              content={
                <StatNumber
                  statNum={impact.statNum}
                  statType={impact.statType}
                />
              }
            />
          ))}
        </Row>
        <h3 className="CPsectionTitle">
          {t('users.civic_participation.collateral_header')}
          {infoMark(t('users.civic_participation.collateral_subheader'))}{' '}
        </h3>
        <Row className="mt-3 mb-5">
          {collateralImpactArray.map(collateral => (
            <StatBox
              label={collateral.label}
              content={
                <StatNumber
                  statNum={collateral.statNum}
                  statType={collateral.statType}
                />
              }
            />
          ))}
        </Row>
        <h3 className="CPsectionTitle">
          {t('users.civic_participation.rating_header')}
          {infoMark(t('users.civic_participation.rating_subheader'))}{' '}
        </h3>
        <Row className="mt-3 mb-5">
          {ratingArray.map(rating => (
            <StatBox
              label={rating.label}
              content={
                <StarRating
                  label={rating.label}
                  onClick={rating.onClick}
                  statNum={rating.statNum}
                  statType={rating.statType}
                />
              }
            />
          ))}
        </Row>
      </Col>
      {data && (
        <MyEventsModal
          show={showMyEventRatingDetails}
          handleClose={handleCloseMyEventRatingDetails}
          statNum={data.stats.average_event_rating}
          reviewCount={t('users.civic_participation.review_count', {
            count: data.stats.event_rating_count,
          })}
        />
      )}
    </Container>
  )
}
