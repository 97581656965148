import React, { useState, useEffect, useMemo } from 'react'
import { Modal } from 'react-bootstrap'
import useApi from '../../../hooks/useApi'
import { useCurrentUser } from '../../../hooks/useCurrentUser'
import styles from './myEventsModal.module.scss'
import { useTranslation } from 'react-i18next'
import Icon from '../../../components/icon'
const StarsRating = ({ statNum }) => {
  if (!statNum) return null

  const stArr = []
  const statNumRounded = Math.round(statNum * 2) / 2

  for (let i = 0; i < 5; i += 1) {
    if (statNumRounded >= i + 1) {
      stArr.push(<Icon name="star" className="starIcon" key={i} />)
    } else if (statNumRounded >= i + 0.5) {
      stArr.push(<Icon name="star-half-stroke" className="starIcon" key={i} />)
    } else {
      stArr.push(<Icon name="star-regular" className="starIcon" key={i} />)
    }
  }

  return <>{stArr}</>
}

export default function MyEventsModal({
  show,
  handleClose,
  statNum,
  reviewCount,
}) {
  const { getMyEvents } = useApi()
  const { currentUser } = useCurrentUser()
  const { t } = useTranslation()

  const [events, setEvents] = useState([])
  const [expandedEvents, setExpandedEvents] = useState({})

  const toggleEventExpansion = eventId => {
    setExpandedEvents({
      ...expandedEvents,
      [eventId]: !expandedEvents[eventId],
    })
  }

  useEffect(() => {
    fetchEvents()
  }, [])

  useEffect(() => {
    const initialExpandedEventState = {}
    events.forEach(event => {
      initialExpandedEventState[event.id] = true
    })

    setExpandedEvents(initialExpandedEventState)
  }, [events])

  const fetchEvents = async () => {
    await getMyEvents({ userId: currentUser.id }).then(response => {
      setEvents(response.data.events)
    })
  }

  return (
    <Modal show={show} onHide={handleClose} centered size="md">
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className={styles.mainContainer}>
          <h4>{t('users.civic_participation.my_events')}</h4>
          <h2 className={styles.mainRatingAverage}>{statNum.toFixed(1)}</h2>
          <span className={styles.stars}>
            <StarsRating key="mainAverage" statNum={statNum} />
          </span>
          <p className="mt-2">{reviewCount}</p>
          {events?.map(event => {
            if (event.volunteers_with_ratings?.length === 0) return null

            return (
              <div key={event.title} className={styles.eventContainer}>
                <div
                  className={styles.eventTitleContainer}
                  onClick={() => toggleEventExpansion(event.id)}
                >
                  <div>{event.title}</div>
                  <div className={styles.eventTitleActions}>
                    <div className={styles.eventAverageRating}>
                      {event.average_event_rating.toFixed(1)}
                    </div>
                    <span>
                      <Icon name="star" className={styles.stars} />
                    </span>
                    <span className={styles.expandOrCollapseIcon}>
                      <Icon
                        name={
                          expandedEvents[event.id]
                            ? 'chevron-up'
                            : 'chevron-down'
                        }
                      />
                    </span>
                  </div>
                </div>
                <div
                  className={`${styles.ratingDetailsContainer} ${
                    expandedEvents[event.id]
                      ? styles.expanded
                      : styles.collapsed
                  }`}
                >
                  {event.volunteers_with_ratings?.map(rating => (
                    <div
                      key={event.title + rating.user_name}
                      className={styles.ratingDetails}
                    >
                      <div className={styles.ratingMainContent}>
                        <div>{rating.user_name}</div>
                        <span className={styles.ratingDetailsStars}>
                          <StarsRating
                            key={event.title + rating.user_name + 'rating'}
                            statNum={rating.event_rating}
                          />
                        </span>
                      </div>
                      <div className={styles.ratingComment}>
                        {rating.event_comment}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )
          })}
        </div>
      </Modal.Body>
    </Modal>
  )
}
