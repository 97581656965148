import 'moment/locale/es'
import { Carousel, Modal } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import parse from 'html-react-parser'
import { useParams, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import moment from 'moment-timezone'
import React, { useCallback, useEffect, useState, useMemo } from 'react'

import './show.scss'
import { formatHashtags } from '../../lib/hashtags'
import { formatLocation } from '../../lib/address'
import { isVideo } from '../../lib/files'
import {
  sortCategoriesAlphabetically,
  sortObjectsAlphabetically,
  sortObjectsByNumber,
} from '../../lib/sort'
import { mobileDeepLink, getTeam } from '../../lib/event'
import { useCurrentUser } from '../../hooks/useCurrentUser'
import useApi from '../../hooks/useApi'
import Button from '../../components/button'
import Icon from '../../components/icon'
import Loading from '../../components/loading'
import NotFound from '../../components/not-found'
import QR from '../../components/QR'
import RequestEventAccess from '../../components/request-event-access'
import SignUpButton from '../../components/Event/signUpButton'
import SponsorAdMessage from '../../components/SponsorAdMessage/SponsorAdMessage'
import TaskAndShiftButton from '../../components/Event/taskAndShiftButton'
import SponsorshipPledgeModal from './sponsorship/modal'

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

export default function EventsShowPage() {
  const { currentUser, locale, business, hasBetaFeature } = useCurrentUser()
  const { t } = useTranslation()
  const params = useParams()

  const { getEvent, getEventAcceptedPledges } = useApi()
  const eventUrl = useMemo(() => window.location.href, [])
  const [refresh, setRefresh] = useState(+new Date())
  const [event, setEvent] = useState()
  const [formattedLocation, setFormattedLocation] = useState('')
  const [unaccesible, setUnaccesible] = useState(undefined)
  const [acceptedPledges, setAcceptedPledges] = useState([])
  const [showSponsorshipButton, setShowSponsorshipButton] = useState(false)
  const [showSponsorshipPledgeModal, setShowSponsorshipPledgeModal] =
    useState(false)
  const [team, setTeam] = useState([])
  const [showShareModal, setShowShareModal] = useState(false)

  const showCarousel = useMemo(
    () => event?.media_attachments?.length > 1,
    [event?.media_attachments?.length],
  )

  const fetchData = async () => {
    try {
      const { data } = await getEvent({ id: params.slug })
      if (data) {
        setEvent(data)
        const { data: dataPledges } = await getEventAcceptedPledges(data.id)
        setAcceptedPledges(sortObjectsByNumber([...dataPledges], 'amount'))
        const owner_admin = data.admins.map(a => a.id).concat(data.user.id)
        setTeam(getTeam(data))
        setShowSponsorshipButton(
          !!currentUser &&
            !!Object.keys(currentUser).length &&
            !!data.volunteer_pay_forward &&
            ((currentUser.business_id && hasBetaFeature('pf_business')) ||
              owner_admin.includes(currentUser.id)),
        )
      }
    } catch (e) {
      setUnaccesible(e.response.status)
    }
  }

  const stringOfEventCategories = useMemo(
    () =>
      sortCategoriesAlphabetically(event?.categories ?? [], locale)
        .map(category =>
          currentUser?.language === 'es' ||
          localStorage.getItem('i18nextLng') === 'es'
            ? category.es_name
            : category.en_name,
        )
        .join(', '),
    [event, locale, currentUser],
  )

  // const onClickBecomeSponsor = useCallback(() => {
  //   window.location.href = window.location.href + '/sponsorship'
  //   // if (currentUser.isBusiness) {
  //   //   return
  //   // } else {
  //   //   window.location.href = '/businesses/new'
  //   // }
  // }, [])

  useEffect(() => {
    fetchData()
  }, [refresh])

  useEffect(() => {
    moment.locale(locale)
  }, [locale])

  useEffect(() => {
    if (event?.location) {
      setFormattedLocation(formatLocation(event.location))
    }
  }, [event?.location])

  const startMoment = moment(event?.event_start_date || undefined).tz(
    moment.tz.guess(),
  )
  const endMoment = (
    event?.event_end_date ? moment(event.event_end_date) : moment().add(1, 'h')
  ).tz(moment.tz.guess())
  const sameDay = startMoment.isSame(endMoment, 'day')

  const thumbnailUrl = event?.media_attachments?.[0]?.thumbnail_url
  const directUploadUrl = event?.media_attachments?.[0]?.direct_upload_url

  // const renderShiftTime = shift => {
  //   const startTime = moment.tz(shift.start_time, moment.tz.guess())
  //   const endTime = moment.tz(shift.end_time, moment.tz.guess())
  //   let time = `${startTime.format('h:mm A')} ${t(
  //     'event_card.to',
  //   )} ${endTime.format('h:mm A')}`
  //   time = `${startTime.format(t('config.short_date_format'))} · ${time}`
  //   return time
  // }

  const onClickShowSponsorshipPledgeModal = useCallback(() => {
    setShowSponsorshipPledgeModal(true)
  }, [])

  const onClosedSponsorshipPledgeModal = useCallback(() => {
    setShowSponsorshipPledgeModal(false)
  }, [])

  if (unaccesible) {
    return (
      <div className="container-fluid">
        <div className="container content">
          <div className="row">
            {!!currentUser?.id &&
              (unaccesible === 403 || unaccesible === 422) && (
                // Logged in, event exists, but user doesn't have access
                <RequestEventAccess eventId={params.slug} />
              )}
            {!currentUser?.id &&
              (unaccesible === 403 || unaccesible === 422) && (
                // Not logged in, event exists
                <NotFound
                  redirectTo={`/events/${params.slug}`}
                  showAuthLinks
                  suggestAuth
                  type="event"
                />
              )}
            {unaccesible === 404 && (
              // Event doesn't exist
              <NotFound
                redirectTo={`/events/${params.slug}`}
                showAuthLinks={!currentUser?.id}
                type="event"
              />
            )}
          </div>
        </div>
      </div>
    )
  }

  if (!event) {
    return <Loading show />
  }

  return (
    <div
      className="bg-image"
      style={{
        backgroundImage: `url(${thumbnailUrl || directUploadUrl})`,
      }}
    >
      <Helmet>
        <title>CivicRush - {event.title}</title>
        <meta property="og:title" content={event.title} />
        <meta property="og:url" content={window.location} />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={`${API_BASE_URL}events/${event.id}/image.jpg`}
        />
        <meta property="og:description" content={event.details} />
        <meta
          name="description"
          content={`CivicRush - ${event.event_type} - ${event.title} - ${moment(
            event.event_start_date,
          ).format(t('config.datetime_picker'))}, ${
            event.remote ? 'Remote event' : formattedLocation
          }. Learn more and participate.`}
        />
        <meta
          name="keywords"
          content={`CivicRush, ${
            event.event_type
          }, ${stringOfEventCategories}, ${
            event.remote ? 'Remote event' : formattedLocation
          }, ${
            event.hashtags
          }. join civic rush, volunteer, donate, give, support, network, 
          outreach, opportunity, philanthropy, charity, giving, community, 
          need, fundraising, fundraising app, event app, social network app, 
          lifestyle app`}
        />
      </Helmet>
      <div className="container-fluid bg-content event-share">
        <div className="container main-content">
          <div className="row">
            <div className="col-12">
              <Carousel
                className="mainImage"
                controls={showCarousel}
                indicators={showCarousel}
              >
                {event.media_attachments.map((media, index) => (
                  <Carousel.Item key={index}>
                    {isVideo(media) ? (
                      <video
                        alt={event.title}
                        className="img-fluid2"
                        controls
                        poster={media.thumbnail_url}
                        src={media.direct_upload_url}
                      />
                    ) : (
                      <img
                        alt={event.title}
                        className="img-fluid2"
                        src={media.direct_upload_url}
                      />
                    )}
                  </Carousel.Item>
                ))}
                {!event.media_attachments?.length ? (
                  <img
                    alt={event.title}
                    className="img-fluid2"
                    src={
                      locale === 'es'
                        ? '/images/draft-media-placeholder-ES.png'
                        : '/images/draft-media-placeholder-EN.png'
                    }
                  />
                ) : null}
              </Carousel>
            </div>
          </div>
          <div className="main-section">
            <div className="col1">
              <div className="item item-title">
                <div className="row no-print">
                  <div className="col d-flex flex-row justify-content-between">
                    <div className="d-flex flex-row">
                      {(currentUser?.id === event.user.id ||
                        event.admins
                          ?.map(a => a.id)
                          .includes(currentUser?.id)) && (
                        <Link
                          to={`/events/${event.slug ?? event.id}/edit`}
                          className="btn btn-primary btn-sm editBt"
                        >
                          <Icon name="edit" className="me-2" />
                          {t('event.edit_post')}
                        </Link>
                      )}
                      <div className="status-bar">
                        {event.drafted_at ? (
                          <Button
                            className="btn-sm ms-3 text-white border-2"
                            variant="warning"
                          >
                            {t('event_card.draft')}
                          </Button>
                        ) : null}
                      </div>
                    </div>
                    <div className="d-flex flex-row">
                      <Link
                        to={`/events/${event.slug ?? event.id}/print/${locale}`}
                        className="btn btn-secondary btn-sm ms-2"
                        target="_blank"
                      >
                        <Icon name="download" className="me-2" />
                        {t('engagements.print.downloadButton')}
                      </Link>
                      {/* <Button
                        className="btn btn-secondary btn-sm ms-2"
                        onClick={() => window.print()}
                      >
                        <Icon name="print" className="me-2" />
                        {t('event.print')}
                      </Button> */}
                      <Button
                        className="btn btn-primary btn-sm ms-2"
                        onClick={() => setShowShareModal(true)}
                      >
                        <Icon name="share" className="me-2" />
                        {t('event.share.title')}
                      </Button>
                      <Modal
                        show={showShareModal}
                        onHide={() => setShowShareModal(false)}
                      >
                        <Modal.Header closeButton>
                          <Modal.Title className="ms-auto p-0">
                            {t('event.share.modal.title')}{' '}
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="text-center">
                            <div className="shareOptionsContainer">
                              <a
                                href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                                  eventUrl,
                                )}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="me-3"
                              >
                                <Icon
                                  name="facebook"
                                  className="fa-2x socialShareIcon"
                                />
                              </a>
                              <a
                                href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
                                  eventUrl,
                                )}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="me-3"
                              >
                                <Icon
                                  name="linkedin"
                                  className="fa-2x socialShareIcon socialShareIconSecondary"
                                />
                              </a>
                              <a
                                href={`mailto:?subject=${encodeURIComponent(
                                  event.title,
                                )}&body=${encodeURIComponent(eventUrl)}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Icon
                                  name="envelope"
                                  className="fa-2x socialShareIcon"
                                />
                              </a>
                            </div>
                            <div className={'shareContainer'}>
                              <div className={'shareBox'}>
                                <div>{t('event.share.modal.url')}</div>
                                <div className={'eventUrl'}>{eventUrl}</div>
                              </div>
                              <button
                                name={t('event.share.modal.copyToClipboard')}
                                className="copyToClipboardButton"
                                onClick={() => {
                                  navigator.clipboard.writeText(eventUrl)
                                  alert(
                                    t('event.share.modal.copiedToClipboard'),
                                  )
                                }}
                              >
                                <Icon
                                  name="copy"
                                  style={'cursor: pointer'}
                                  onClick={() => {
                                    navigator.clipboard.writeText(
                                      window.location.href,
                                    )
                                  }}
                                />
                              </button>
                            </div>
                          </div>
                        </Modal.Body>
                      </Modal>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <h2 className="title">{event.title}</h2>
                  </div>
                </div>
              </div>
              <div className="item item-detail">
                <div className="row">
                  <div className="col">
                    <div
                      className={`details${
                        !event.details?.length ? ' defaultText' : ''
                      }`}
                    >
                      <div className="html-content">
                        {event.details && !!event.details.length
                          ? parse(event.details)
                          : t('event.detailsDefault')}
                      </div>
                    </div>
                    {!!event.volunteers_details?.length && (
                      <div className="details">
                        <p className="fw-medium pb-0 volunteer-info-label">
                          {t('event.volunteer_info')}
                        </p>
                        <div className="html-content">
                          {parse(event.volunteers_details)}
                        </div>
                      </div>
                    )}
                    {!!event.hashtags?.length && (
                      <div className="details hashtags">
                        <p className="pb-0">{formatHashtags(event.hashtags)}</p>
                      </div>
                    )}
                    <div className="details publisher">
                      <p className="pb-0">
                        {t('event.by')}{' '}
                        <span className="username">{event.user?.name}</span>
                      </p>
                    </div>
                    {!!team.length && (
                      <div className="details team">
                        <p className="pb-0">
                          <span>{t('event.team')}</span>
                          <span className="ms-1 text-reset fw-semibold">
                            {sortObjectsAlphabetically(team, 'name')
                              .map(t => t.name)
                              .join(', ')}
                          </span>
                        </p>
                      </div>
                    )}
                    {!!event.groups && (
                      <div className="details assocGrpList">
                        <p className="pb-0">
                          <span>{t('event.private_groups')}</span>
                          <span className="ms-1">
                            {sortObjectsAlphabetically(
                              event.groups ?? [],
                              'name',
                            ).map(group => (
                              <a
                                key={group.id}
                                href={`/groups/${group?.slug ?? group.id}`}
                                target="_blank"
                                className="groups-event-link text-reset fw-semibold"
                                title={group.name}
                              >
                                {group.name}
                              </a>
                            ))}
                          </span>
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="item item-category">
                <div className="categoryList">
                  {!!event.categories && !!event.categories.length ? (
                    sortCategoriesAlphabetically(event.categories, locale).map(
                      category => (
                        <div className="category-pill" key={category.id}>
                          <Icon name={category.icon} />{' '}
                          {currentUser?.language === 'es' ||
                          localStorage.getItem('i18nextLng') === 'es'
                            ? category.es_name
                            : category.en_name}
                        </div>
                      ),
                    )
                  ) : (
                    <div className="category-pill" key={25}>
                      <Icon name="check" />{' '}
                      {currentUser?.language === 'es' ||
                      localStorage.getItem('i18nextLng') === 'es'
                        ? 'Mejorar la Comunidad'
                        : 'Community Improvement'}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col2">
              <div className="item item-qr">
                <div className="qr-section d-flex flex-column align-items-center">
                  <div className="logo-print">
                    <img src="/images/CR-Logo-PrintEvent.png" />
                  </div>
                  <QR
                    title={event.title}
                    value={mobileDeepLink(event)}
                    size={150}
                    color={'var(--CRPURPLE)'}
                  />
                </div>
                <div className="d-flex flex-column align-items-center">
                  {event.event_type !== 'need' && (
                    <p className="dateTime">
                      <span className="sectitle">{t('event.date_time')}</span>
                      <br />
                      <span
                        className={
                          !event?.event_start_date ? 'defaultText' : undefined
                        }
                      >
                        {startMoment.format(t('config.date_format'))}
                        {!sameDay && (
                          <span id="date-span">
                            &nbsp;
                            {t('event.to')}
                            &nbsp;
                            {endMoment.format(t('config.date_format'))}
                          </span>
                        )}
                        <br />
                        {startMoment.format('h:mm a')}
                        <span id="time-span">
                          &nbsp;
                          {t('event.to')}
                          &nbsp;
                          {endMoment.format('h:mm a z')}
                        </span>{' '}
                        <span className="timezone" />
                      </span>
                    </p>
                  )}

                  <p className="dateTime">
                    <span className="sectitle">{t('event.location')}</span>
                    <br />
                    {!!event.remote && t('event.remote')}
                    {!event.remote && !!formattedLocation && (
                      <a
                        href={`https://www.google.com/maps/search/?api=1&query=${encodeURI(
                          formattedLocation,
                        )}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {formattedLocation}
                      </a>
                    )}
                    {!event.remote && !formattedLocation && (
                      <span className="defaultText">
                        {t('event.locationDefault')}
                      </span>
                    )}
                  </p>

                  {!!event.service_hours && (
                    <div className="serviceHours">
                      <Icon name="user-clock" />{' '}
                      {t('event_card.has_service_hours')}
                    </div>
                  )}

                  <SignUpButton event={event} />
                  <TaskAndShiftButton event={event} setRefresh={setRefresh} />
                </div>
              </div>
              <div className="item item-sponsor">
                <div className="d-flex flex-column align-items-center">
                  {showSponsorshipButton && !acceptedPledges?.length && (
                    <div className="mt-2 mb-2">
                      {/* <Link
                    className="link"
                    state={{ event }}
                    target="_blank"
                    to="./sponsorship"
                    onClick={onClickBecomeSponsor}
                  > */}
                      <Button
                        className="btn btn-primary"
                        onClick={onClickShowSponsorshipPledgeModal}
                      >
                        {t('event_card.sponsorship_opportunity')}
                      </Button>
                      {/* </Link> */}
                    </div>
                  )}

                  {acceptedPledges?.length ? (
                    <div className="sponsorAdMessageContainer">
                      <p className="d-flex justify-content-between mb-1">
                        <span className="sponsored-by">
                          {t('event.sponsored_by')}
                        </span>
                        {showSponsorshipButton && (
                          <Link
                            className="link no-print"
                            state={{ event }}
                            // target="_blank"
                            // to="./sponsorship"
                            onClick={onClickShowSponsorshipPledgeModal}
                          >
                            {t('event.learn_more')}
                          </Link>
                        )}
                      </p>
                      {acceptedPledges.map(pledge => (
                        <div className="sponsorAdMessage">
                          <SponsorAdMessage business={pledge.business} />
                        </div>
                      ))}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="footer-print">
              <div className="col1">&nbsp;</div>
              <div className="col2">
                <img src="/images/getApp-QrCode.png" />
              </div>
            </div>
          </div>
          <div className="row m-0 no-print">
            <div className="col-12 p-0 bottom-banner"></div>
          </div>
        </div>
        <SponsorshipPledgeModal
          business={business}
          event={event}
          onClose={onClosedSponsorshipPledgeModal}
          show={showSponsorshipPledgeModal}
        />
      </div>
    </div>
  )
}
